<template>
	<div class="employee publicBox">
		<navBar name="员工管理" :list="navlist"></navBar>
		<div class="content-main">
			<el-form inline>
				<el-form-item>
					<!-- <my-input placeholder="输入部门名称" v-model="searchForm.branch_name">
						<template #pre>部门名称</template>
					</my-input>-->
					<el-select v-model="searchForm.branchId" @change="turnPage" clearable placeholder="选择部门">
						<el-option v-for="item in departList.arr" :key="item.branchId" :value="item.branchId"
							:label="item.branchName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<my-input placeholder="输入工号" v-model="searchForm.userNo" Imodel="searchForm.user_id">
						<template #pre>工号</template>
					</my-input>
				</el-form-item>
				<el-form-item>
					<my-input placeholder="输入员工姓名" v-model="searchForm.nickName">
						<template #pre>员工姓名</template>
					</my-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker type="daterange" @change="timeChange" v-model="searchForm.entryTime"
						value-format="YYYY-MM-DD"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="选择性别" @change="turnPage" v-model="searchForm.userSex" clearable>
						<el-option :value="-1" label="保密"></el-option>
						<el-option :value="1" label="男"></el-option>
						<el-option :value="2" label="女"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<!-- <el-button>搜索</el-button> -->
					<myButton title="搜索" @click="turnPage">
						<template #preImage>
							<img class="icon" src="../assets/search.png" alt />
						</template>
					</myButton>
				</el-form-item>
			</el-form>
			<el-form inline>
				<el-form-item>
					<myButton title="新增" @click="editAdd()">
						<template #preImage>
							<img class="icon" src="../assets/zj.png" alt />
						</template>
					</myButton>

					<!-- <el-button  @click="editAdd()">新增</el-button> -->
				</el-form-item>
				<el-form-item>
					<myButton title="导出" @click="exported(false)">
						<template #preImage>
							<img class="icon" src="../assets/down.png" alt />
						</template>
					</myButton>
					<!-- <el-button type="primary" @click="exported(false)">导出</el-button> -->
				</el-form-item>
				<el-form-item>
					<myButton title="批量员工信息导入" @click="importVasible = true"></myButton>
					<!-- <el-button  @click="importVasible = true">批量员工信息导入</el-button> -->
				</el-form-item>
			</el-form>
			<div class="table-box">
				<el-table :data="tableData.arr" :cell-style="{ 'text-align': 'center', 'height': '48px' }"
					:header-cell-style="{ 'text-align': 'center', 'background': 'rgb(245, 245, 245)', 'height': '48px' }">
					<el-table-column type="index" width="100px" label="序号"></el-table-column>
					<el-table-column label="工号" prop="userNo"></el-table-column>
					<el-table-column label="员工姓名" prop="nickName"></el-table-column>
					<el-table-column label="性别">
						<template v-slot="scope">
							<span>{{ scope.row.userSex == -1 ? '保密' : scope.row.userSex == 1 ? '男' : '女' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="所属部门" prop="branchName">
						<template v-slot="{ row }">{{ row.branchName ? row.branchName : '' }}</template>
					</el-table-column>
					<el-table-column label="员工职位" prop="role_name">
						<template v-slot="{ row }">{{ row.management ? row.management.managementName : '/' }}</template>
					</el-table-column>
					<el-table-column label="联系电话" prop="userPhone"></el-table-column>
					<el-table-column label="入职时间" prop="entryTime"></el-table-column>
					<el-table-column label="操作">
						<template v-slot="{ row }">
							<!-- <el-button type="text" @click="editAdd(row)">编辑</el-button> -->
							<div class="popbox">
								<pop tips="编辑" popRight>
									<img src="../assets/img/edit.png" @click="editAdd(row)" class="popIcon" alt />
								</pop>
								<pop tips="重置密码" popRight>
									<img src="../assets/img/resit.png" class="popIcon" @click="resitPas(row)" alt />
								</pop>
								<pop tips="删除" :disabled="row.xfCmsId == row.userId">
									<img src="../assets/img/deleted.png" class="popIcon" alt
										@click="row.xfCmsId != row.userId && deleted(row)" />
								</pop>
							</div>

							<!-- <el-button type="text" @click="resitPas(row)" style="color: red">重置密码</el-button> -->
							<!-- <el-button
							type="text"
							@click="deleted(row)"
							v-if="row.xfCmsId != row.userId"
							style="color: red"
							>删除</el-button>-->
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- <div class="publicPage">
				<el-pagination :total="total" @current-change="getList" :page-size="10"></el-pagination>
			</div>-->
			<div class="public-page">
				<el-pagination :total="total" next-text="下一页" prev-text="上一页" @current-change="getList"
					layout="total,prev,pager,next"></el-pagination>
			</div>

			<el-dialog v-model="vasible" :title="setType == 'add' ? '添加' : '编辑'" destroy-on-close @close="clear">
				<el-form label-width="120px" label-position="right" ref="editForms" :model="editForm" :rules="rules">
					<el-form-item label="姓名" prop="nickName">
						<el-input class="public-input" v-model="editForm.nickName" placeholder="输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="所属部门" prop="branchId">
						<el-select class="public-input" v-model="editForm.branchId">
							<el-option v-for="item in departList.arr" :key="item.branchId" :value="item.branchId"
								:label="item.branchName"></el-option>
							<el-option label="黑大帅" :key="55" value="发大水发"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="身份证号" prop="userIdCode">
						<el-input class="public-input" v-model="editForm.userIdCode" placeholder="输入身份证号"></el-input>
					</el-form-item>
					<el-form-item label="入职时间" prop="entryTime">
						<el-date-picker value-format="YYYY-MM-DD" v-model="editForm.entryTime"></el-date-picker>
					</el-form-item>
					<el-form-item label="年龄" prop="userAge">
						<el-input class="public-input" v-model.number="editForm.userAge" placeholder="输入年龄"></el-input>
					</el-form-item>
					<el-form-item label="职位" prop="managementId">
						<el-select class="public-input" v-model="editForm.managementId">
							<el-option v-for="item in posList.arr" :key="item.managementId" :value="item.managementId"
								:label="item.managementName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="性别" prop="userSex">
						<el-select v-model="editForm.userSex">
							<el-option label="保密" :value="-1"></el-option>
							<el-option label="男" :value="1"></el-option>
							<el-option label="女" :value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="联系电话" prop="userPhone">
						<el-input class="public-input" v-model.number="editForm.userPhone" :maxlength="11"></el-input>
					</el-form-item>
					<el-form-item label="居住地址" prop="userAddress">
						<el-input class="public-input" v-model="editForm.userAddress"></el-input>
					</el-form-item>
					<el-form-item label="上传头像" prop="headerImg">
						<el-upload action :auto-upload="false" :on-change="upChange" :show-file-list="false" accept="image/*"
							ref="filelists">
							<img :src="editForm.headerImg" class="headerImg" v-if="editForm.headerImg" />
							<el-button type="primary" v-else>选择图片</el-button>
						</el-upload>
					</el-form-item>
				</el-form>
				<template #footer>
					<myButton title="取消" @click="vasible = false"></myButton>
					<myButton title="确认" style="margin-left: 15px;" type="primary" @click="submit"></myButton>
					<!-- <el-button @click="vasible = false">取消</el-button> -->
					<!-- <el-button type="primary" @click="submit">确认</el-button> -->
				</template>
			</el-dialog>

			<!--  -->
			<el-dialog title="批量员工信息导入" v-model="importVasible" @close="clearFile">
				<el-row>
					<el-col :span="4">
						<el-upload action="ss" :auto-upload="false" ref="upfile" :show-file-list="false" :on-change="importChange"
							:multiple="false">
							<myButton title="上传文件" type="primary"></myButton>
							<!-- <el-button type="primary">上传文件</el-button> -->
						</el-upload>
					</el-col>
					<el-col :span="4">
						<myButton @click="exported(true)" title="下载模板" type="primary"></myButton>
						<!-- <el-button type="primary" @click="exported(true)">下载模板</el-button> -->
					</el-col>
				</el-row>
				<el-row>
					<span style="font-size: 12px; color: #999; margin-top: 5px">注:员工职位只能填写系统已有职位，系统不存在的职位无法导入！</span>
				</el-row>
				<template #footer>
					<myButton title="取消" @click="importVasible = false"></myButton>
					<!-- <el-button @click="importVasible = false">取消</el-button> -->
					<!-- <el-button @click="imported" type="primary" :disabled="!importdis">导入</el-button> -->
					<myButton title="导入" @click="imported" type="primary" :disabled="!importdis" style="margin-left:15px">
					</myButton>
				</template>
			</el-dialog>
		</div>
	</div>
</template>
<script setup>
import {
	ref,
	reactive,
	inject,
	getCurrentInstance
} from "vue";
import { ElNotification, ElMessageBox } from 'element-plus'
let vm = inject("plugin");
let navlist = []
let { ctx: that } = getCurrentInstance()
let tableData = reactive({
	arr: []
});
const imgUrl = process.env.VUE_APP_URL
let vasible = ref(false);
let importVasible = ref(false);
let total = ref(0);
//筛选form
let searchForm = reactive({
	userId: '',
	nickName: '',
	startTime: '',
	endTime: '',
	userSex: '',
	branch_name: '',
	pageNum: 1,
	pageSize: 10,
	userNo: ''
})
// 添加编辑form
let editForm = reactive({
	nickName: '',
	userIdCode: '',
	entryTime: [],
	userAge: '',
	userSex: '',
	userPhone: '',
	userAddress: '',
	headerImg: '',
	branchId: '', //部门id
	managementId: '' //职位id
});

// get---------
function getList (e = 1) {
	searchForm.page = e
	vm.HttpGet("/admin/user/list", searchForm).then((res) => {
		if (res.data) {
			if (res.data.list) {
				res.data.list.forEach(item => {
					if (item.userSex == 0) {
						item.userSex = -1;
					}
				});
			}
			tableData.arr = res.data.list;
			total.value = res.data.total;
		}
	});
}
// 获取部门
function timeChange (date) {
	if (date) {
		searchForm.startTime = date[0];
		searchForm.endTime = date[1]
	} else {
		searchForm.startTime = '';
		searchForm.endTime = ''
	}
	turnPage()
}
getList();
getDepartList()

function turnPage (e) {
	searchForm.pageNum = e;
	getList()
}

// -------------
function resitPas (item) {
	// vm.confirm("确认重置密码为123456?", "提示").then(() => {
	// 	vm.HttpPost('/admin/InitUserPassword', { userId: item.userId, userPhone: item.userPhone })
	// 		.then((res) => {
	// 			vm.message(res.msg)
	// 		})
	// })

	ElMessageBox.confirm('确认重置密码为123456？', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	}).then(() => {
		vm.HttpPost('/admin/InitUserPassword', { userId: item.userId, userPhone: item.userPhone }).then((res) => {
			vm.message(res.msg)
		})
	})
}

function deleted (item) {
	ElMessageBox.confirm('确认删除该员工', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	}).then(() => {
		vm.HttpPost('/admin/user/deleteByIds', { ids: [item.userId] }).then(res => {
			vm.message(res.msg)
			getList()
		})
	})
}

// --------新增/编辑--------
let setType = ref('add')
function editAdd (item) {
	getPosList();
	getDepartList()
	setType.value = 'add';
	if (item) {
		for (let i in editForm) {
			editForm[i] = item[i]
		}
		editForm.userId = item.userId
		setType.value = 'edit'
	}

	vasible.value = true;

}
let posList = reactive({ arr: [] })
async function getPosList () {  //职位
	let res = await vm.HttpGet('/admin/management/list', { pageSize: 9999, pageNum: 1 });
	posList.arr = res.data.list;
	that.$forceUpdate()
}

let departList = reactive({ arr: [] })
async function getDepartList () {
	let res = await vm.HttpGet('/admin/branch/list', { pageSize: 9999, pageNum: 1 });
	departList.arr = res.data.list;
	that.$forceUpdate()

}
// 编辑、添加保存
let editForms = ref(null);

function submit () {
	let url = '';
	setType.value == 'add' ? url = '/admin/user/create' : url = '/admin/user/update'
	editForms.value.validate((valid) => {
		if (valid) {
			vm.HttpPost(url, editForm).then(res => {
				vm.message(res.msg);
				vasible.value = false;
				getList()
			})
		}
	});
}
// 上传头像
async function upChange (file) {
	let obj = new FormData();
	obj.append('file', file.raw)
	let res = await vm.HttpPost('/admin/upload/upload', obj);
	editForm.headerImg = imgUrl + '/' + res.data.file.url;
	editForms.value.clearValidate()

}

// 导出
async function exported (exportStatus) {
	let res = await vm.HttpGet('/admin/user/export', { ...searchForm, exportStatus }, { responseType: 'blob' });
	let blob = new Blob([res]);
	let href = URL.createObjectURL(blob)
	let link = document.createElement('a');
	link.href = href;
	let name = ''
	exportStatus ? name = '模板.xlsx' : name = '员工列表.xlsx'
	link.download = name;
	document.body.appendChild(link)
	link.click();
	URL.revokeObjectURL(blob);
	document.body.removeChild(link);
}
// 导入
let importForm = new FormData();
let importdis = ref(false);
function importChange (file) {
	if (importForm.has('file')) {
		importForm.delete('file')
	}
	importForm.append('file', file.raw);
	importdis.value = true;
}
function imported () {
	if (importForm.has('file')) {
		vm.HttpPost('/admin/user/import', importForm)
			.then(res => {
				vm.message(res.msg);
				setTimeout(() => {
					importVasible.value = false;

				}, 200);
				getList()
			})
	}

}

let upfile = ref(null)
function clearFile () {
	upfile.value.clearFiles();
	importForm.delete('file');
	importdis.value = false
}
// ------------------清空数据

function clear () {  //新增/编辑
	for (let i in editForm) {
		editForm[i] = ''
	}
	editForms.value.clearValidate()
}
//-------------------
const rules = {
	nickName: [{
		required: true,
		message: "请输入姓名",
		trigger: "blur",
	},],
	branchId: [{
		required: true,
		message: '请选择部门',
		trigger: 'change'
	}],
	userIdCode: [{
		required: true,
		message: '请输入身份证号',
		trigger: 'blur'
	}],
	entryTime: [{
		required: true,
		message: '请选择入职时间',
		trigger: 'blur'
	}],
	userAge: [{
		required: true,
		message: '请输入年龄',
		trigger: 'blur'
	},
	{
		required: true,
		message: "请输入正确年龄",
		trigger: "blur",
		type: "number",
	},
	],
	userPhone: [{
		required: true,
		message: '请输入联系方式',
		trigger: 'blur'
	}
	],
	userAddress: [{
		required: true,
		message: '请输入地址',
		trigger: 'blur'
	}],
	headerImg: [{
		required: true,
		message: '请上传头像',
		trigger: 'change'
	}],
	managementId: [{
		required: true,
		message: '请选择职位'
	}],
	userSex: [{
		required: true,
		message: '请选择性别'
	}]
};
</script>
<style lang="scss">
.headerImg {
	width: 100px;
	height: 100px;
}
</style>
